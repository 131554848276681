/**
 * Main.js
 * Require all the needed modules
 */

var $ = window.$ = window.jQuery = require('jquery');

require('jquery-validation');
require('slick-carousel');


// Active Links
// ------------

var page = $('body').data('page').trim();
$('.js-page-' + page).addClass('is-active');


// Validations
// -----------

$.extend($.validator.messages, {
  required: 'Campo obrigatório',
  email: 'E-mail inválido'
});

$('.js-validate').validate({
  errorPlacement: function (error, input) {
    error.appendTo(input.parents('.input-container'));
  }
});

$('.js-validate-newsletter').validate({
  errorPlacement: function (error, input) {
    error.prependTo(input.parents('.input-container'));
  }
})

// Menu
// ----

var $menu = $('.js-menu');
var $btnMenu = $('.js-btn-menu');

$btnMenu.on('click', function (e) {
  e.preventDefault();
  $btnMenu.add($menu).toggleClass('is-active');
});


// Home Slider
// -----------

$('.js-home-banner').each(function () {
  var $this = $(this);
  var src = $this.data('src');
  $this.css('background-image', 'url("' + src + '")');
});

$('.js-home-slider').slick({
  prevArrow: '.js-home-slider-prev',
  nextArrow: '.js-home-slider-next',
  dots: true
});


// Carousel
// --------

$('.js-carousel').slick({
  slidesToShow: 3,
  infinite: false,
  prevArrow: '.js-carousel-prev',
  nextArrow: '.js-carousel-next',
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});


// JS Back
// -------

$('.js-btn-back').on('click', function (e) {
  e.preventDefault();
  window.history.go(-1);
});


// Tabs
// ----

var $activeTab = $();
var $activeLink = $();

$('.js-tab').on('click', function (e) {
  e.preventDefault();

  var $this = $(this);
  var $tab = $($this.attr('href'));

  if ($this.is($activeTab)) {
    return ;
  }

  $activeTab.removeClass('is-active');
  $activeTab = $tab.addClass('is-active');
  $activeLink.removeClass('is-active');
  $activeLink = $this.addClass('is-active');
}).eq(0).trigger('click');

